import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31c67c3a = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _d47642a0 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _93e81dbe = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0b3be448 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _8397835c = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _75720094 = () => interopDefault(import('../pages/UIKITS.vue' /* webpackChunkName: "pages/UIKITS" */))
const _f175da46 = () => interopDefault(import('../pages/world.vue' /* webpackChunkName: "pages/world" */))
const _6b42a297 = () => interopDefault(import('../pages/tutor-request/course.vue' /* webpackChunkName: "pages/tutor-request/course" */))
const _26fe1243 = () => interopDefault(import('../pages/tutor-request/form/index.vue' /* webpackChunkName: "pages/tutor-request/form/index" */))
const _119acbe5 = () => interopDefault(import('../pages/tutor-request/calendar/_id.vue' /* webpackChunkName: "pages/tutor-request/calendar/_id" */))
const _b8b177c2 = () => interopDefault(import('../pages/tutor-request/confirm/_id.vue' /* webpackChunkName: "pages/tutor-request/confirm/_id" */))
const _4516f75b = () => interopDefault(import('../pages/tutor-request/description/_id.vue' /* webpackChunkName: "pages/tutor-request/description/_id" */))
const _4cbefd8c = () => interopDefault(import('../pages/tutor-request/finish/_id.vue' /* webpackChunkName: "pages/tutor-request/finish/_id" */))
const _54ba590c = () => interopDefault(import('../pages/tutor-request/languages/_id.vue' /* webpackChunkName: "pages/tutor-request/languages/_id" */))
const _2889e6b0 = () => interopDefault(import('../pages/tutor-request/price/_id.vue' /* webpackChunkName: "pages/tutor-request/price/_id" */))
const _4c7b665e = () => interopDefault(import('../pages/tutor-request/tracking/_id.vue' /* webpackChunkName: "pages/tutor-request/tracking/_id" */))
const _27b67cdc = () => interopDefault(import('../pages/tutor/_name.vue' /* webpackChunkName: "pages/tutor/_name" */))
const _3520d9e8 = () => interopDefault(import('../dynamic-pages/tutor-list.vue' /* webpackChunkName: "" */))
const _1436801d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _723b82a0 = () => interopDefault(import('../../config/pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _6cbb10ce = () => interopDefault(import('../../config/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4c30a220 = () => interopDefault(import('../../config/pages/auth/login-by-oauth.vue' /* webpackChunkName: "pages/auth/login-by-oauth" */))
const _77b81175 = () => interopDefault(import('../../config/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _78349351 = () => interopDefault(import('../../config/pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _7700f603 = () => interopDefault(import('../../config/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _57df74e4 = () => interopDefault(import('../../config/pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _31c67c3a,
    name: "about-us___en"
  }, {
    path: "/en/contact-us",
    component: _d47642a0,
    name: "contact-us___en"
  }, {
    path: "/en/faq",
    component: _93e81dbe,
    name: "faq___en"
  }, {
    path: "/en/privacy-policy",
    component: _0b3be448,
    name: "privacy-policy___en"
  }, {
    path: "/en/terms",
    component: _8397835c,
    name: "terms___en"
  }, {
    path: "/en/UIKITS",
    component: _75720094,
    name: "UIKITS___en"
  }, {
    path: "/en/world",
    component: _f175da46,
    name: "world___en"
  }, {
    path: "/en/tutor-request/course",
    component: _6b42a297,
    name: "tutor-request-course___en"
  }, {
    path: "/en/tutor-request/form",
    component: _26fe1243,
    name: "tutor-request-form___en"
  }, {
    path: "/en/tutor-request/calendar/:id?",
    component: _119acbe5,
    name: "tutor-request-calendar-id___en"
  }, {
    path: "/en/tutor-request/confirm/:id?",
    component: _b8b177c2,
    name: "tutor-request-confirm-id___en"
  }, {
    path: "/en/tutor-request/description/:id?",
    component: _4516f75b,
    name: "tutor-request-description-id___en"
  }, {
    path: "/en/tutor-request/finish/:id?",
    component: _4cbefd8c,
    name: "tutor-request-finish-id___en"
  }, {
    path: "/en/tutor-request/languages/:id?",
    component: _54ba590c,
    name: "tutor-request-languages-id___en"
  }, {
    path: "/en/tutor-request/price/:id?",
    component: _2889e6b0,
    name: "tutor-request-price-id___en"
  }, {
    path: "/en/tutor-request/tracking/:id?",
    component: _4c7b665e,
    name: "tutor-request-tracking-id___en"
  }, {
    path: "/en/tutor/:name?",
    component: _27b67cdc,
    name: "tutor-name___en"
  }, {
    path: "/en/tutor-list/:param1?/:param2?/:param3?/:param4?/:param5?",
    component: _3520d9e8,
    name: "tutor-list___en"
  }, {
    path: "/",
    component: _1436801d,
    name: "index"
  }, {
    path: "/en/auth/forget-password",
    component: _723b82a0,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _6cbb10ce,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-by-oauth",
    component: _4c30a220,
    name: "auth-login-by-oauth___en"
  }, {
    path: "/en/auth/logout",
    component: _77b81175,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/set-password",
    component: _78349351,
    name: "auth-set-password___en"
  }, {
    path: "/en/auth/signup",
    component: _7700f603,
    name: "auth-signup___en"
  }, {
    path: "/en/auth/verify",
    component: _57df74e4,
    name: "auth-verify___en"
  }, {
    path: "/fr/auth/forget-password",
    component: _723b82a0,
    name: "auth-forget-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _6cbb10ce,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-by-oauth",
    component: _4c30a220,
    name: "auth-login-by-oauth___fr"
  }, {
    path: "/fr/auth/logout",
    component: _77b81175,
    name: "auth-logout___fr"
  }, {
    path: "/fr/auth/set-password",
    component: _78349351,
    name: "auth-set-password___fr"
  }, {
    path: "/fr/auth/signup",
    component: _7700f603,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/verify",
    component: _57df74e4,
    name: "auth-verify___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
